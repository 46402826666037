import $ from 'jquery';
import {Pagination, Autoplay, Mousewheel, EffectFade, Navigation} from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Mousewheel, EffectFade, Navigation])

let testimonialsSlider = $('.js-testimonials-slider');

if (testimonialsSlider.length > 0) {
  testimonialsSlider.each(function () {
    let testimonialsSliderSwiper = new Swiper(this, {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      spaceBetween: 20,

      navigation: {
        nextEl: '.swiper-button-next-custom',
        prevEl: '.swiper-button-prev-custom',
      },
    });

    let slideCount = testimonialsSliderSwiper.slides.length;
    if (slideCount < 2) {
      $('.js-testimonials-slider .swiper-button-prev-custom,.js-testimonials-slider .swiper-button-next-custom').remove();
    }

    testimonialsSliderSwiper.init();
  });
}

